import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from "ng-recaptcha-2";
import { AssetsService } from '../core/api/assets/assets.service';
import { IconComponent } from './components/icon/icon.component';
import { NgxExtendedPdfViewerVVModule } from './../shared/ngx-extended-pdf-viewer-vv/src/public_api';
import { NgxExtendedPdfViewerNoprintComponent } from './components/ngx-extended-pdf-viewer-noprint/ngx-extended-pdf-viewer-noprint.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DocumentViewerModule } from './document-viewer/document-viewer.module';
import { MaterialFileInputModule } from './ngx-material-file-input/material-file-input.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PrimengModule } from './primeng/primeng.module';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getSpanishPaginatorIntl } from '../core/utilities/locale/es-mx.intl';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FontAwesomeModule,
    NgHttpLoaderModule.forRoot(),
    ReactiveFormsModule,
    NgxExtendedPdfViewerVVModule,
    RecaptchaModule,
    NgxExtendedPdfViewerModule,
    DocumentViewerModule,
    EditorComponent,
    MaterialFileInputModule,
    NgScrollbarModule,
    PrimengModule
  ],
  declarations: [
    IconComponent,
    NgxExtendedPdfViewerNoprintComponent,
  ],
  exports: [
    MaterialModule,
    FontAwesomeModule,
    NgHttpLoaderModule,
    ReactiveFormsModule,
    NgxExtendedPdfViewerVVModule,
    RecaptchaModule,
    EditorComponent,
    NgxExtendedPdfViewerModule,
    NgxExtendedPdfViewerNoprintComponent,
    IconComponent,
    DocumentViewerModule,
    MaterialFileInputModule,
    NgScrollbarModule,
    PrimengModule
  ],
  providers: [
    AssetsService,
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
  ]
})
export class UIModule {
  
}
