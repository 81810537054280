import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';

import { SocialServiceService } from '../../../../../../core/api/social-service/social-service.service';
import { SocialServiceProgram } from '../../../../interfaces/social-service/social-service-program.interface';

export interface IPeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfigService } from '../../../../../../core/config/services/config.service';

@UntilDestroy()
@Component({
  selector: 'app-social-service-program',
  templateUrl: './social-service-program.component.html',
  styleUrls: ['./social-service-program.component.scss']
})
export class SocialServiceProgramComponent implements OnInit {

  @Input("selectedCareer") selectedCareer: string;
  @Input("selectedSector") selectedSector: string;
  @Input("selectedProgram") selectedProgram: string;
  @Output("Back") Exit: EventEmitter<boolean> = new EventEmitter<boolean>();

  public elementData: SocialServiceProgram[];
  public displayedColumns: string[] = ["CompanyName", "ProgramId", "ProgramName", "GeographicalArea"];
  public logoUrl: string;
  public programData: SocialServiceProgram;
  public careerDescription: string;

  constructor(
    public route: ActivatedRoute,
    private socialServiceService: SocialServiceService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.socialServiceService.getPrograms(this.selectedCareer, this.selectedSector).pipe(untilDestroyed(this)).subscribe(data =>  {
      this.elementData = data;
      var selectedProgram = this.selectedProgram;
      this.programData = this.elementData.find( (program: SocialServiceProgram)=>{ return program.ProgramId === selectedProgram });
    });
    this.logoUrl = `${this.socialServiceService.getLogoUrl()}?${this.configService.get('storage.blob.signature')}`;
    window.scrollTo(0, 0);
  }
  goBack(): void {
    this.Exit.emit(true);
  }

}
