// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-btn {
  font-size: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/tool-bar/tool-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF","sourcesContent":[".icon-btn {\r\n  font-size: 1em;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
