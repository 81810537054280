import { Component, Input, OnInit } from '@angular/core';
import { INotificationData } from '../../../../core/redux/notification/interfaces/inotification-data';
import { NotificationFacade } from '../../../../core/redux/notification/facades/notification-facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  isCollapsed: boolean = true;
  @Input('notification') notification: INotificationData;
  constructor(
    private notificationFacade: NotificationFacade,
  ) { }

  ngOnInit() {
  }

  toggle(event: Event) {
    event.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
  }

  remove() {
    this.notificationFacade.remove(this.notification);
  }
}
