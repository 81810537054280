import { Inject, Injectable } from '@angular/core';
import { ConfigService } from '../../../config/services/config.service';
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  StorageService,
} from 'ngx-webstorage-service';
import { IToken } from 'src/app/core/redux/confirmation/interfaces/iconfirmation-status';
import { jwtDecode  } from 'jwt-decode';
import { IReenrollmentState } from 'src/app/core/redux/reenrollment/interfaces/ireenrollment-status';
import { IReportReenrollmentState } from 'src/app/core/redux/report-reenrollment/interfaces/ireport-reenrollment-status';
import { MsalService } from '@azure/msal-angular';
import { ITitleRequestState } from 'src/app/core/redux/title-request/interfaces/ititle-request-status';

export const REQUESTED_URL = '__auth.requested.url';
export const REQUESTED_URL_QUERIES = '__auth.requested.query';
export const HIDE_SIDEBAR = '__auth.hide.sidebar';
export const HIDE_SIDEBAR_TIME = '__auth.hide.sidebar.time';
export const PROFILE_URL = '/user/profile';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private appConfig: ConfigService,
    @Inject(LOCAL_STORAGE) private localStorage: StorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private msalService: MsalService
  ) {

  }

  /**
   * @returns {adal.Config}
   */
  getConfig() {
    return this.msalService.instance.getConfiguration();//this.config;
  }

  /**
   * @returns {adal.User}
   */
  getUserInfo() {
    //  console.log('getUserInfo: ', this.msalService.instance.getActiveAccount());
    return this.msalService.instance.getActiveAccount();//this.userInfo;
  }

  /**
   * @returns {boolean}
   */
  isAuthenticated() {
    return !!this.getUserInfo().idToken;
  }

  /**
   * @returns {boolean}
   */
  canAccess() {
    try {
      return this.isAuthenticated() && this.isAllowedTenant();
    } catch (e) {
      return false;
    }
  }

  /**
   * @returns {boolean}
   */
  isAllowedTenant() {
    const tenantId = this.getUserInfo().idTokenClaims.tid;
    const configTenants = this.appConfig.get('oauth2.allowedTenants');
    const keys = Object.keys(configTenants);
    const tenants = keys.map((key) => configTenants[key]);
    return tenants.indexOf(tenantId) !== -1;
  }

  isStudentTenant() {
    if (this.isAllowedTenant()) {
      const tenantId = this.getUserInfo().idTokenClaims.tid;
      const configTenants = this.appConfig.get('oauth2.allowedTenants');
      const tenant = configTenants['students'];
      return tenantId == tenant;
    }
  }

  isTutorTenant() {
    if (this.isAllowedTenant()) {
      const tenantId = this.getUserInfo().idTokenClaims.tid;
      const configTenants = this.appConfig.get('oauth2.allowedTenants');
      const tenant = configTenants['tutors'];
      return tenantId == tenant;
    }
  }

  isAdminTenant() {
    if (this.isAllowedTenant()) {
      const tenantId = this.getUserInfo().idTokenClaims.tid;
      const configTenants = this.appConfig.get('oauth2.allowedTenants');
      const tenant = configTenants['admins'];
      return tenantId == tenant;
    }
  }

  getTenant() {
    return this.getUserInfo().idTokenClaims.tid;
  }

  clearSession() {
    //this.clearCache();
    this.sessionStorage.clear();
    this.localStorage.clear();
  }

  storeRequestedUrl(url: string) {
    this.localStorage.set(REQUESTED_URL, url);
  }

  getRequestedUrl() {
    return this.localStorage.get(REQUESTED_URL);
  }

  removeRequestedUrl() {
    this.localStorage.remove(REQUESTED_URL);
  }

  GetResourceForEndpoint(url: string) {
    return url;
  }

  getConfirmationToken(): IToken {
    if (
      this.localStorage.get('_app_state')['confirmation'] != null &&
      typeof this.localStorage.get('_app_state')['confirmation'] !== 'undefined'
    ) {
      if (
        this.localStorage.get('_app_state')['confirmation']['accessToken'] !=
        null &&
        typeof this.localStorage.get('_app_state')['confirmation'][
        'accessToken'
        ] !== 'undefined'
      ) {
        if (
          typeof this.localStorage.get('_app_state')['confirmation'][
          'accessToken'
          ] === 'object' &&
          this.localStorage.get('_app_state')['confirmation']['accessToken']
            .token
        ) {
          return this.localStorage.get('_app_state')['confirmation'][
            'accessToken'
          ];
        }
      }
    }
    return { token: '' };
  }

  decodeToken(): any {
    let token = this.getUserInfo().idToken;
    return jwtDecode (token);
  }

  groupsFromStorage() {
    let groups: string[] = [];
    const groupStr = window.localStorage.getItem('adal.groups.ad');
    if (groupStr) {
      groups = JSON.parse(groupStr);
    }

    return groups;
  }

  getReenrollmentToken() {
    if (
      this.localStorage.get('_app_state')['reenrollment'] != null &&
      typeof this.localStorage.get('_app_state')['reenrollment'] !== 'undefined'
    ) {
      const getReenrollment: IReenrollmentState =
        this.localStorage.get('_app_state')['reenrollment'];
      return { token: getReenrollment.tokenData };
    }
    return { token: '' };
  }

  getReportReenrollmentToken() {
    if (
      this.localStorage.get('_app_state')['reportReenrollment'] != null &&
      typeof this.localStorage.get('_app_state')['reportReenrollment'] !== 'undefined'
    ) {
      const getReportReenrollment: IReportReenrollmentState =
        this.localStorage.get('_app_state')['reportReenrollment'];
      return { token: getReportReenrollment.tokenData };
    }
    return { token: '' };
  }

  getTimbradosToken() {
    if (
      this.localStorage.get('_app_state')['titleRequest'] != null &&
      typeof this.localStorage.get('_app_state')['titleRequest'] !== 'undefined'
    ) {
      const titleRequestState: ITitleRequestState = this.localStorage.get('_app_state')['titleRequest'];
      return { token: titleRequestState.token };
    }
    return { token: '' };
  }

  logOut() {
    this.msalService.logoutRedirect();
  }

}
