import { Component, OnInit, ViewChild, OnDestroy, Injector } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MessageService } from 'src/app/core/services/message/message.service';
import { Observable, Subscription, of } from 'rxjs';
import { UserFacade } from 'src/app/core/redux/user/facades/user-facade';
import { IUserData } from 'src/app/core/redux/user/interfaces/iuser-data';
import { PaymentFacade } from 'src/app/core/redux/payment/facades/payment-facade';
import { INotificationData } from 'src/app/core/redux/notification/interfaces/inotification-data';
import { NotificationFacade } from 'src/app/core/redux/notification/facades/notification-facade';
import { WaitMessagePayment } from 'src/app/core/data/constants/wait.message';
import { IMethods, IPaymentDataModel, IPaymentsModel } from '../../../interfaces/ipayment-data-model';
import { PaymentService } from '../../../../../core/api/payment/payment.service';
import { CreateElementsService } from '../../../../../core/services/create-elements/create-elements.service';
import { IEventPyment } from 'src/app/modules/payment/modules/bank-bridge/interfaces/ievent-payment';
import { IPaymentState, IPaymentData } from 'src/app/core/redux/payment/interfaces/ipayment-status';
import { NavigationService } from '../../../../../core/services/navigation/navigation.service';
import { IMinResponse, IResponse } from 'src/app/core/http/interfaces/iresponse';
import { IPaymentAdditionalDataModel, header } from '../../../interfaces/ipayment-additional-data-model';
import { ScriptBanamex, ScriptBankbridgeV2, ScriptFlywire, ScriptMercadoPago } from 'src/app/core/data/constants/predefine-scripts';
import { postRoutes, Origins, cancelMessages, mapRoutes } from '../../../catalogs/services';
import { IAdditionalData } from '../../../interfaces/iadditional-data';
import { IPaymentBehavour } from 'src/app/core/interfaces/ibank-statement';
import { ConfigService } from 'src/app/core/config/services/config.service';
import { IBBPaymentViewModel } from '../../../interfaces/ipayment-new';
import { PAYMENT_CHANNELS_CAT, POSITIVE_BALANCE_CHANNEL } from '../../../catalogs/payment-channels';
import { IPaymentCodi } from '../../../interfaces/ipayment-codi';
import { IPaymentChannel } from '../../../interfaces/ipayment-channel';
import { IFlywireStudenData } from '../../../modules/flywire/interfaces/iflywire';

export const _PREVIUS_URL: string = 'payment/pay/process/';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-request-payment',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestPaymentComponent extends BaseComponent implements OnInit, OnDestroy {


  C_PAYMENT_CHANNELS_CAT = PAYMENT_CHANNELS_CAT;
  paymentChannels: IPaymentChannel[] = [];//PAYMENT_CHANNELS.filter(c => c.action == "Enable");
  paymentChannelSelected: string;

  // Form for pay with positive balance
  requestPaymentForm: FormGroup;

  // Facade observables for profile info and notifications
  allPayNotifications$: Observable<INotificationData[]> = this.notificationFacade.getAllFor('payment.pay');
  profile$: Observable<IUserData>;
  payment$: IPaymentState;
  profile: IUserData;
  paymentRequestId: string | null;

  moduleService: any;

  paymentData: IPaymentDataModel = <IPaymentDataModel>{};

  isGonnaPayPositiveBalance = false;
  isLoadedPayment = false;

  isUpdatingTracker: true;
  paymentApplied = false;
  proccesHadStarted = false;
  proccesPayHadFinished = false;
  alreadyLoaded = false;
  alreadyPaymentApplied = false;
  validatePostPaymentSubscription: null | Subscription = null;

  checkPaymentSuscriber: Subscription;
  paymentSuscribe: Subscription;
  allNotificationsSubscription: Subscription;
  allPaidNotificationsSubscription: Subscription;
  estimatedTimeTimer: any;

  finalTotal = 0;
  paymentRequestsListOrig: Array<any> = [];
  nombre_operacion: string;
  paymentProcessedId: any;
  paymentId: any | null;
  procedureSelected = [];

  paymentBbData: IBBPaymentViewModel | null;


  paymentCodiData: IPaymentCodi;
  codiAmount = '';

  flywireRequestId;
  flywireRequest;
  flywireAmount;

  mercadopagoRequestId;
  mercadopagoAmount;

  waitText = 'Cargando información del pago, por favor espera.';
  conuterTexts = 0;

  @ViewChild('payments') table: MatTable<any>;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [];
  nameDisplayedColumn: string[] = [];

  paymentOriginModule: string;

  flywireStudentData: IFlywireStudenData;

  origin: string;
  lsb: Subscription;

  isPaying = false;

  __bankbridge_version_ = 1;

  rulesToDisplay: IPaymentBehavour = <IPaymentBehavour>{
    displayAsTable: true,
    dispayAsOverdue: false,
    showPositiveBalance: true,
    readonlyPositiveBalance: false,
    goBackCustom: false,
    requestId: null,
    customEnding: false,
    satateData: null
  };

  aceptConditions = false;

  clicked = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private messageService: MessageService,
    private userFacade: UserFacade,
    private paymentFacade: PaymentFacade,
    private notificationFacade: NotificationFacade,
    private createElements: CreateElementsService,
    private navigationService: NavigationService,
    private injector: Injector,
    private configService: ConfigService
  ) {
    super();
    this.dataSource = new MatTableDataSource([]);
    this.userFacade.profile$.pipe(untilDestroyed(this)).subscribe(profile => {
      this.profile$ = of(profile);
      this.profile = profile;
      this.flywireStudentData = {
        sender_first_name: profile.firstName,
        sender_last_name: `${profile.lastName} ${profile.secondLastName}`,
        sender_email: profile.id
      } as IFlywireStudenData;
    });
    this.paymentFacade.payment$.pipe(untilDestroyed(this)).subscribe((payment: IPaymentState) => this.payment$ = payment);
    this.__bankbridge_version_ = this.configService.get('bankbridge.version');
  }

  ngOnInit() {
    if (!this.navigationService.getPreviousUrl().includes(_PREVIUS_URL)) {
      this.router.navigateByUrl('/user/profile');
      return;
    }
    this.initRequestForm();
    this.estimatedTime();
    this.handlePostPaymentNotifications();
    this.init();

  }

  ngOnDestroy() {
    // console.log('DESTORYING PYMENT COMPONENT... ');
    if (this.paymentSuscribe) {
      this.paymentSuscribe.unsubscribe();
    }
    if (this.allNotificationsSubscription) {
      this.allNotificationsSubscription.unsubscribe();
    }
    if (this.allPaidNotificationsSubscription) {
      this.allPaidNotificationsSubscription.unsubscribe();
    }
    if (this.checkPaymentSuscriber) {
      this.checkPaymentSuscriber.unsubscribe();
    }
    if (this.lsb) {
      this.lsb.unsubscribe();
      this.lsb = null;
    }
    if (this.validatePostPaymentSubscription != null) {
      this.validatePostPaymentSubscription.unsubscribe();
      this.validatePostPaymentSubscription = null;
    }
    this.paymentFacade.removePayment(<IPaymentData>{});
    this.profile$ = null;
    this.payment$ = null;
    this.exitEstimatedTime();
    if (this.__bankbridge_version_ == 2) {
      this.createElements.removeElement(ScriptBankbridgeV2.id, 'script');
    }
    else {
      this.createElements.removeElement(ScriptBanamex.id, 'script');
    }
    this.createElements.removeElement(ScriptFlywire.id, 'script');
  }

  init() {
    let error = false;
    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      this.paymentRequestId = params.id;
      this.loadPayment();
    }, () => {
      error = true;
    }, () => {
      if (error) {
        this.go2Home();
      } else {
        console.log('why not here');
      }
    });
  }

  loadPayment() {
    if (this.payment$ == null)
      return;

    if (this.payment$.active) {
      if (this.payment$.process.other != null) {
        this.rulesToDisplay = this.payment$.process.other.rulesToDisplay || {};
      }
      if (this.payment$.paymentOrderId === this.paymentRequestId) {
        this.moduleService = this.injector.get(this.payment$.process.services[0]);
        this.validatePayment(this.payment$);
        return;
      }
    }
    this.go2Home();
  }

  validatePayment(payment: IPaymentState) {
    if (this.profile$ == null)
      return;

    this.paymentOriginModule = payment.process.module;
    //console.log(this.paymentOriginModule);

    this.validate(this.profile, payment);
  }

  validate(profile: IUserData, payment: IPaymentState) {
    this.lsb = this.paymentService.validatePayment(payment.paymentOrderId, profile.id)
      .pipe(untilDestroyed(this)).subscribe({
        next: (resp: IMinResponse<string, IAdditionalData>) => {
          if ((resp.data != null && typeof resp.data !== 'undefined' && resp.data != '') &&
            resp.additionalData != null && typeof resp.additionalData !== 'undefined') {
            if (resp.data.toLowerCase().includes('requested')) {
              this.getToPay(payment);
            } else if (resp.data.toLowerCase().includes('paid')) {
              this.goTo(resp.additionalData.originService, resp.additionalData.transactionId);
            } else if (resp.data.toLowerCase().includes('canceled')) {
              this.isCanceled();
            }
          } else {
            this.messageService.error('No fué posible recuperar la información del pago', null, () => {
              this.go2Home();
            });
          }
        }, error: err => {
          this.go2Home();
        }, complete: () => {
          if (this.lsb) {
            this.lsb.unsubscribe();
            this.lsb = null;
          }
        }
      });
  }

  getToPay(payment: IPaymentState) {
    this.profile$.pipe(untilDestroyed(this)).subscribe(profile => {
      this.moduleService[payment.process.request[0]](profile.id, profile.id, payment.process.data[0].solicitudId).pipe(untilDestroyed(this)).subscribe((response: IMinResponse<IPaymentsModel, IPaymentAdditionalDataModel>) => {
        if (response != null && typeof response === 'object') {
          if (this.rulesToDisplay.displayAsTable) {
            this.afterLoadedData(response);
          }
          else {
            this.afterLoadedNoTableData(response);
          }
          return;
        }
      });
    });
  }

  afterLoadedData(payment: IMinResponse<IPaymentsModel, IPaymentAdditionalDataModel>) {
    this.validateObject(payment.additionalData);
    this.validateObject(payment.data);
    this.paymentData = { ...payment.data, payments: [], minimunAmountOnlinePayment: (payment.data.minPayOnline != null ? payment.data.minPayOnline : 100), conceptToGetOnline: false };
    this.initColumns(payment.additionalData.header);
    this.preparePayments(payment.additionalData.body);
    this.loadPaymentChannels(payment.additionalData.paymentChannels);
    this.afterInit();

    this.setInitValues(payment.data.methods);

  }

  afterLoadedNoTableData(payment: IMinResponse<IPaymentsModel, IPaymentAdditionalDataModel>) {
    this.validateObject(payment);
    this.paymentData = { ...payment.data, payments: [], minimunAmountOnlinePayment: (payment.data.minPayOnline != null ? payment.data.minPayOnline : 100), conceptToGetOnline: false };
    this.loadPaymentChannels(payment.additionalData.paymentChannels);
    this.afterInit();
  }

  validateObject(object) {
    if (object != null && typeof object === 'object')
      return;
    this.messageService.error('No fué posible cargar la información de pago', null, () => { this.go2Home(); });
  }

  loadPaymentChannels(channels: IPaymentChannel[] = []) {
    if (channels == null || typeof channels === 'undefined')
      channels = [];

    //channels = [channels[0]];
    switch (channels.length) {
      case 0:
        this.messageService.warning('No hay metodos de pago configurados en este momento. Por favor intenta más tarde.');
        break;
      default:
        this.paymentChannels = channels.filter(c => c.action == "Enable");
        if (this.paymentChannels.length == 0)
          this.messageService.warning('No hay metodos de pago configurados en este momento. Por favor intenta más tarde.');
        break;
    }
    if (this.paymentChannels.length == 1) {
      this.requestPaymentForm.get('paymentChannel').setValue(this.paymentChannels[0].key);
    }
  }

  getChannelDescription() {
    if (this.paymentChannels.length == 0 || !this.requestPaymentForm.get('paymentChannel').value)
      return 'Pagar';
    let channel = this.paymentChannels.find(c => c.key == this.requestPaymentForm.get('paymentChannel').value);
    if (channel)
      return channel.name;
  }

  getIcon() {
    let icon = null;
    if (this.paymentChannels.length == 0 || !this.requestPaymentForm.get('paymentChannel').value)
      return icon;

    let channel: IPaymentChannel = this.paymentChannels.find(c => c.key == this.requestPaymentForm.get('paymentChannel').value);

    if (channel) {
      icon = this.getKeyIcon(channel.key);
    }
    return icon;
  }

  getKeyIcon(key: string) {
    let icon = null;
    switch (key) {
      case 'Online':
      case 'Flywire':
        icon = ['fas', 'credit-card'];
        break;
      case 'CoDi':
        icon = ['fas', 'qrcode'];
        break;
      case 'MercadoPago':
        icon = ['fas', 'fa-credit-card-front'];
        return
      default:
        icon = ['fas', 'money-bill'];
        break;
    }
    return icon;
  }

  initColumns(headers: header[]) {
    this.displayedColumns = new Array<string>(headers.length);
    this.nameDisplayedColumn = new Array<string>(headers.length);
    headers.forEach((h: header) => {
      this.displayedColumns[h.order] = h.id;
      this.nameDisplayedColumn[h.order] = h.value;
    });
  }

  initRequestForm() {
    this.requestPaymentForm = this.formBuilder.group({
      positiveBalancePay: [],
      positiveBalanceTotal: [],
      paymentChannel: ['']
    });
    if (this.paymentApplied) {
      this.requestPaymentForm.get('paymentChannel').disable({ onlySelf: true, emitEvent: false });
    } else {
      this.requestPaymentForm.get('paymentChannel').enable({ onlySelf: true, emitEvent: false });
    }
  }

  preparePayments(paymentsBefore: any[]) {
    let payments: any[] = [];
    paymentsBefore.forEach((payment: any, i: number) => {
      let toPay: any = {};
      this.displayedColumns.forEach((key: string, j: number) => toPay[key] = payment[key]);
      payments.push(toPay);
    });
    this.paymentData.payments = payments;

  }

  afterInit() {
    if (this.rulesToDisplay.displayAsTable)
      this.loadDataTable();

    this.requestPaymentForm.get('positiveBalancePay').valueChanges.pipe(untilDestroyed(this)).subscribe((data: string) => {
      if (this.rulesToDisplay.showPositiveBalance)
        this.watchPositiveBalancePayControl(data);
    });
    setTimeout(() => {
      this.isLoadedPayment = true;
    }, 1);
  }

  setInitValues(methods: IMethods[]) {
    methods = methods || [];
    methods.forEach(methodObject => {
      if (methodObject.method == 'PositiveBalance') {
        this.setAmountToPayWithPositiveBalance(methodObject.amount);
        this.watchPositiveBalancePayControl(methodObject.amount.toString());
      }
    });
  }

  watchPositiveBalancePayControl(data: string) {
    if (data == null || typeof data === 'undefined') {
      data = '-1';
    }
    if (data != null && typeof data === 'string') {
      if (Number(data) > 0) {
        if (data.includes('.')) {
          const amounts = data.split('.');
          data = amounts[0];
        }
        this.addPositiveBalanceConcept(Number(data));
        this.updatePositiveBalance(this.paymentData.positiveBalance - Number(data));
        if (this.paymentData.positiveBalance <= Number(data)) {
          if (this.paymentData.positiveBalance > this.finalTotal) {
            this.addPositiveBalanceConcept(this.finalTotal);
            this.setAmountToPayWithPositiveBalance(this.finalTotal);
            this.updatePositiveBalance(this.paymentData.positiveBalance - this.finalTotal);
          } else if (this.paymentData.positiveBalance <= this.finalTotal) {
            this.addPositiveBalanceConcept(this.paymentData.positiveBalance);
            this.setAmountToPayWithPositiveBalance(this.paymentData.positiveBalance);
            this.updatePositiveBalance(this.paymentData.positiveBalance - this.paymentData.positiveBalance);
          }
        } else if (this.paymentData.positiveBalance > Number(data) && this.finalTotal <= Number(data)) {
          this.setAmountToPayWithPositiveBalance(this.finalTotal);
          this.addPositiveBalanceConcept(this.finalTotal);
          this.updatePositiveBalance(this.paymentData.positiveBalance - this.finalTotal);
        }
      } else {
        this.resetOriginalPayments();
        this.updatePositiveBalance(this.paymentData.positiveBalance);
      }
    } else {
      this.updatePositiveBalance(this.paymentData.positiveBalance);
    }
  }

  addPositiveBalanceConcept(amount: number) {
    this.isGonnaPayPositiveBalance = true;
    const first = this.paymentData.payments[0];
    const concepto = <any>{ cycle: first.cycle, concept: 'Saldo a Favor', amount: -1 * Number(amount) };
    let result = this.paymentData.payments.find(obj => {
      return obj.concept === 'Saldo a Favor';
    });
    if (result) {
      this.paymentData.payments[this.paymentData.payments.findIndex(v => v.concept === 'Saldo a Favor')].amount = concepto.amount;
    } else {
      this.paymentData.payments.push(concepto);
    }
    this.updateDataSource(this.paymentData.payments);
  }

  loadDataTable() {
    //console.log('Loading dataTable...');
    this.setDataSource(this.paymentData);
    this.updatePositiveBalance(this.paymentData.positiveBalance);
    const total = this.getTotals();
    this.finalTotal = total;
    if (this.paymentData.positiveBalance) {
      if (total > 0 && total < this.paymentData.positiveBalance) {
        // this.requestPaymentForm.get('positiveBalancePay').setValue(total);
        // this.addPositiveBalanceConcept(total);
      } else if (total > 0 && total > this.paymentData.positiveBalance) {
        // this.requestPaymentForm.get('positiveBalancePay').setValue(this.positiveBalance);
        // this.addPositiveBalanceConcept(this.positiveBalance);
      } else if (total === this.paymentData.positiveBalance) {
        // this.requestPaymentForm.get('positiveBalancePay').setValue(this.positiveBalance);
        // this.addPositiveBalanceConcept(this.positiveBalance);
      } else if (total === 0) {
        this.requestPaymentForm.get('positiveBalancePay').disable();
      }
    }
    if (!this.paymentData.canPayOnline &&
      ((this.finalTotal > 0 && !this.paymentData.positiveBalance) || this.finalTotal > this.paymentData.positiveBalance)) {
      setTimeout(() => {
        this.canNotPayOnline();
      }, 200);
    }
  }

  getTotals() {
    if (this.paymentData && this.paymentData.payments) {
      const total = this.paymentData.payments.map(t => t.amount).reduce((acc, value) => acc + value, 0);
      return this.onlyPositiveBalance(total);
    }
    return 0;
  }

  canNotPayOnline() {
    this.messageService.info(`FORMA DE PAGO NO AUTORIZADA.<br><br>
    Cuenta no autorizada para realizar pagos por este medio. Debe hacer sus pagos en las cajas de su campus o por Depósito Bancario.`,
      null, () => {
        this.router.navigate(['user/profile']);
      });
  }

  onlyPositiveBalance(amount: number): number {
    const positiveBalanceChannel = POSITIVE_BALANCE_CHANNEL;
    if (amount > 0) {
      if (this.paymentChannels.find(c => c.key == 'OnlinePB')) {
        this.paymentChannels = this.paymentChannels.filter(c => c.key != 'OnlinePB');
        this.requestPaymentForm.get('paymentChannel').setValue(positiveBalanceChannel.key);
        this.requestPaymentForm.enable({ onlySelf: true, emitEvent: false });
      }
    } else {
      if (!this.rulesToDisplay.dispayAsOverdue && !this.paymentChannels.find(c => c.key == 'OnlinePB')) {
        this.paymentChannels.push(positiveBalanceChannel);
        this.requestPaymentForm.get('paymentChannel').setValue(positiveBalanceChannel.key);
        this.requestPaymentForm.disable({ onlySelf: true, emitEvent: false });
      }
    }
    return amount;
  }

  setDataSource(source: IPaymentDataModel) {
    this.paymentRequestsListOrig = JSON.parse(JSON.stringify(source.payments));
    this.updateDataSource(JSON.parse(JSON.stringify(source.payments)));
  }

  updateDataSource(source: Array<any>) {
    if (source) {
      this.dataSource = new MatTableDataSource(source);
      if (this.table) {
        this.table.renderRows();
      }
    }
  }

  resetOriginalPayments() {
    this.isGonnaPayPositiveBalance = false;
    this.paymentData.payments = JSON.parse(JSON.stringify(this.paymentRequestsListOrig));
    this.updateDataSource(this.paymentData.payments);
  }

  updatePositiveBalance(positiveBalance: number) {
    this.requestPaymentForm.get('positiveBalanceTotal').setValue(positiveBalance);
  }

  setAmountToPayWithPositiveBalance(amount: number) {
    this.requestPaymentForm.get('positiveBalancePay').setValue(amount);
  }

  processPayment(event: PointerEvent) {
    console.log('processPayment', event);
    if (event.detail == 1) {
      let formData = this.requestPaymentForm.getRawValue();
      if (formData.paymentChannel) {
        switch (formData.paymentChannel) {
          case PAYMENT_CHANNELS_CAT.online:
          case PAYMENT_CHANNELS_CAT.onlinePB:
            this.createElements.LoadScript(this.__bankbridge_version_ == 2 ? ScriptBankbridgeV2 : ScriptBanamex);
            this.paymentChannelSelected = PAYMENT_CHANNELS_CAT.online;
            let total = this.getTotals();
            if (total > 0) {
              if (this.paymentData.canPayOnline) {
                this.paymentProcess();
              } else {
                if (this.paymentData.positiveBalance > this.finalTotal) {
                  this.paymentProcess();
                }
              }
            }
            if (total == 0) {
              this.paymantFinalProcess();
            }
            break;
          case PAYMENT_CHANNELS_CAT.flywire:
            this.createElements.LoadScript(ScriptFlywire);
            this.paymentChannelSelected = PAYMENT_CHANNELS_CAT.flywire;
            this.paymentProcess();
            break;
          case PAYMENT_CHANNELS_CAT.codi:
            this.paymentChannelSelected = PAYMENT_CHANNELS_CAT.codi;
            this.paymentProcess();
            break;
          case PAYMENT_CHANNELS_CAT.mercadopago:
            this.createElements.LoadScript(ScriptMercadoPago);
            this.paymentChannelSelected = PAYMENT_CHANNELS_CAT.mercadopago;
            this.paymentProcess();
            break;
        }
      }
      else {
        this.messageService.warning("Debes seleccionar una método de pago");
      }
    }

  }

  paymentProcess() {
    const toPay = this.getTotals();
    if (!this.paymentData.canPayOnline && this.paymentData.positiveBalance > 0) {
      if (toPay > 0) {
        this.messageService.info(`Debes cubrir el monto total con saldo a favor.<br><br>
        Debido a que tu cuenta no esta autorizada para realizar pagos por otro medio.`);
        return;
      } else {
        this.paymantFinalProcess(toPay);
        return;
      }
    }
    if (this.paymentData.minimunAmountOnlinePayment > toPay && (this.paymentChannelSelected != PAYMENT_CHANNELS_CAT.codi && this.paymentChannelSelected != PAYMENT_CHANNELS_CAT.flywire && this.paymentChannelSelected != PAYMENT_CHANNELS_CAT.mercadopago)) {
      this.messageService.info(`El pago no puede ser procesado, el monto mínimo para pagar en línea es ${this.paymentData.minimunAmountOnlinePayment}.00MXN`);
      return;
    } else {
      this.paymantFinalProcess(toPay);
    }
  }

  payWithCodi() {
    const toPay = this.getTotals();
    if (!this.paymentData.canPayOnline && this.paymentData.positiveBalance > 0) {
      if (toPay > 0) {
        this.messageService.info(`Debes cubrir el monto total con saldo a favor.<br><br>
        Debido a que tu cuenta no esta autorizada para realizar pagos por otro medio.`);
        return;
      } else {
        this.paymantFinalProcess(toPay);
        return;
      }
    }
    this.paymantFinalProcess(toPay);
  }


  paymentWithCodiFinalProcess(toPay: number = null) {

  }


  paymantFinalProcess(toPay: number = null) {
    let concept = <any>{};
    let concepts: any[] = JSON.parse(JSON.stringify(this.paymentData.payments));

    if (toPay) {
      concept = <any>{ cycle: 'Ciclo Actual', concept: 'Pago en línea', amount: Number(toPay) };
      concepts.push(concept);
    }

    let onlineAmount = 0;
    let positiveBalanceAmount = 0;

    concepts.forEach((pay: any) => {
      if (pay.concept === 'Saldo a Favor') {
        positiveBalanceAmount = positiveBalanceAmount + pay.amount;
      }
      if (pay.concept === 'Pago en línea') {
        onlineAmount = onlineAmount + pay.amount;
      }
    });

    if (this.rulesToDisplay.dispayAsOverdue)
      onlineAmount = this.paymentData.amount;

    const body = {
      onlineAmount: this.absolutoDe(onlineAmount),
      positiveBalanceAmount: this.absolutoDe(positiveBalanceAmount),
      conditionsAccepted: this.aceptConditions
    };

    if (this.clicked)
      return;

    setTimeout(() => this.beforePay(body, toPay), 100);
  }

  beforePay(body: any, toPay: any) {
    if (this.clicked)
      return;
    this.clicked = true;
    setTimeout(() => this.pay(body, toPay), 2500)
  }

  pay(body: any, toPay: any) {

    if (this.paymentChannelSelected == PAYMENT_CHANNELS_CAT.codi) {
      this.paymentService.requestPaymentChanelProcess(body, this.paymentRequestId, this.profile.id, 'codi')
        .subscribe({
          next: (data: IResponse<IPaymentCodi, string>) => {
            //console.log('requestPaymentCodiProcess: ', data);
            if (data != null && typeof data !== 'undefined') {
              if (!!data.additionalData) {
                if (data.additionalData === '-1') {
                  const event: IEventPyment = { success: true, event: 'success', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.codi };
                  this.onComplete(event);
                } else {
                  if (!!data.message && !data.data) {
                    this.messageService.warning(data.message, null, () => {
                      this.aceptConditions = true;
                      this.clicked = false;
                      this.paymantFinalProcess(toPay);
                    }, () => { });
                  }
                  else {
                    this.paymentCodiData = data.data;
                    this.codiAmount = data.additionalData;
                    this.isPaying = true;
                  }
                }
              }
              else {
                if (!!data.message && !data.data) {
                  this.messageService.warning(data.message, null, () => {
                    this.aceptConditions = true;
                    this.clicked = false;
                    this.paymantFinalProcess(toPay);
                  }, () => { });
                }
                else if (!data.message && !data.data) {
                  const event: IEventPyment = { success: true, event: 'success', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.codi };
                  this.onComplete(event);
                }
                else {
                  this.paymentCodiData = data.data;
                  this.isPaying = true;
                }
              }
            }
          }, error: error => {
            this.clicked = false;
          }
        });
    }
    else if (this.paymentChannelSelected == PAYMENT_CHANNELS_CAT.online) {
      if (!!this.__bankbridge_version_ && this.__bankbridge_version_ == 2) {
        this.paymentService.requestPaymentProcessV2(body, this.paymentRequestId, this.profile.id)
          .pipe(untilDestroyed(this)).subscribe({
            next: (data: IMinResponse<IBBPaymentViewModel, string>) => {
              if (data != null && typeof data !== 'undefined') {
                if (data.data) {
                  this.paymentBbData = data.data;
                  this.isPaying = true;
                } else {
                  const event: IEventPyment = { success: true, event: 'success', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.online };
                  this.onComplete(event);
                }
              }
            }, error: error => {
              this.clicked = false;
            }
          });
      }
      else {
        this.profile$.pipe(untilDestroyed(this)).subscribe(profile => {
          this.paymentService.requestPaymentProcess(body, this.paymentRequestId, profile.id)
            .pipe(untilDestroyed(this)).subscribe({
              next: (data: any) => {
                if (data != null && typeof data !== 'undefined') {
                  if (data.isSuccessStatusCode) {
                    if (data.data) {
                      if (data.data === '-1') {
                        const event: IEventPyment = { success: true, event: 'success', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.online };
                        this.onComplete(event);
                      } else {
                        this.paymentId = data.data;
                        this.isPaying = true;
                      }
                    }
                  }
                }
              }, error: error => {
                this.clicked = false;
              }
            });
        });
      }
    }
    /** FLYWIRE */
    else if (this.paymentChannelSelected == PAYMENT_CHANNELS_CAT.flywire) {
      this.paymentService.requestPaymentChanelProcess(body, this.paymentRequestId, this.profile.id, 'flywire')
        .subscribe({
          next: (data: IResponse<number, string>) => {
            if (data != null && typeof data !== 'undefined') {
              if (!!data.additionalData) {
                if (data.additionalData === '-1') {
                  const event: IEventPyment = { success: true, event: 'success', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.flywire };
                  this.onComplete(event);
                } else {
                  this.flywireRequest = data.data;
                  this.flywireAmount = data.additionalData;
                  this.isPaying = true;
                }
              }
              else {
                if (!data.message && `${data.data}` == '-1') {
                  const event: IEventPyment = { success: true, event: 'success', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.flywire };
                  this.onComplete(event);
                }
                else {
                  // TODO Agregar funcionalidad desdpues de obtener el bbid para flywire
                  this.flywireRequest = data.data;
                  this.flywireAmount = data.additionalData;
                  this.isPaying = true;
                }
              }
            }
          }, error: error => {
            this.clicked = false;
          }
        });
    }
    /** MERCADOPAGO */
    else if (this.paymentChannelSelected == PAYMENT_CHANNELS_CAT.mercadopago) {
      this.paymentService.requestPaymentChanelProcess(body, this.paymentRequestId, this.profile.id, 'mercadopago')
        .subscribe({
          next: (data: IResponse<number, string>) => {
            if (data != null && typeof data !== 'undefined') {
              if (!!data.additionalData) {
                if (data.additionalData === '-1') {
                  const event: IEventPyment = { success: true, event: 'success', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.mercadopago };
                  this.onComplete(event);
                } else {
                  this.mercadopagoRequestId = data.data;
                  this.mercadopagoAmount = data.additionalData;
                  this.isPaying = true;
                }
              }
              else {
                if (!data.message && `${data.data}` == '-1') {
                  const event: IEventPyment = { success: true, event: 'success', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.mercadopago };
                  this.onComplete(event);
                }
                else {
                  // TODO Agregar funcionalidad desdpues de obtener el bbid para flywire
                  this.mercadopagoRequestId = data.data;
                  this.mercadopagoAmount = data.additionalData;
                  this.isPaying = true;
                }
              }
            }
          }, error: error => {
            this.clicked = false;
          }
        });
    }
  }

  absolutoDe(value: number) {
    return value < 0 ? -1 * value : value;
  }

  cancelRequest() {
    const msg = cancelMessages.find(m => m.id == this.payment$.process.module).message;
    this.messageService.info(`${msg} <br><br>
      ¿Deseas continuar con la cancelación?`, null, () => {
      this.cancelAndReturn()
    }, () => {
      return;
    });
  }

  cancelAndReturn() {
    this.paymentService.cancelPayment(this.paymentRequestId, this.profile.id).subscribe(data => {
      if (data) {
        this.messageService.success('La solicitud de pago se canceló de forma exitosa.', null,
          () => {
            if (this.rulesToDisplay.goBackCustom) {
              const toGo = mapRoutes.find(r => r.id == this.paymentOriginModule).route;
              let state: { [name: string]: any };
              if (!!this.rulesToDisplay.satateData) {
                let auxState = JSON.parse(JSON.stringify(this.rulesToDisplay.satateData));
                auxState['canceled'] = true;
                state = { state: auxState };
              }
              else {
                state = { state: this.rulesToDisplay.requestId };
              }
              this.router.navigate([toGo], state);
            } else {
              this.router.navigate(['/user/profile']);
            }
          });
        return;
      }
      this.messageService.info('No fué posible cancelar el pago');
    });
  }

  isPaymentDone(queueId) {
    // console.log('is isPaymentDone called with: ', queueId, 'at:  ' + new Date().toDateString());
    setTimeout(() => {
      let isSuccess = false;
      if (!this.alreadyPaymentApplied) {
        this.validatePostPaymentSubscription = this.paymentService.validatePostPayment(queueId)
          .pipe(untilDestroyed(this)).subscribe({
            next: data => {
              if (data) {
                isSuccess = true;
                this.getPaymentSummary();
              }
            }, error: err => {

            }, complete: () => {
              if (this.validatePostPaymentSubscription != null) {
                this.validatePostPaymentSubscription.unsubscribe();
                this.validatePostPaymentSubscription = null;
              }
              if (!isSuccess) {
                this.isPaymentDone(queueId);
              }
            }
          });
      }
    }, 20000);
  }

  handlePostPaymentNotifications() {
    this.allPaidNotificationsSubscription = this.allPayNotifications$.pipe(untilDestroyed(this)).subscribe(notifications => {
      if (notifications != null && typeof notifications !== 'undefined') {
        notifications.forEach(notification => {
          if (this.paymentProcessedId) {
            if (!this.proccesPayHadFinished) {
              if (this.paymentProcessedId === notification.data) {
                if (!this.alreadyPaymentApplied) {
                  this.getPaymentSummary();
                }
              }
            }
          }
        });
      }
    });
  }

  goTo(origin: string, params: any = null, queyParams: any = {}, state: any = {}) {
    const module = typeof Origins.find(o => o.id == origin) === 'undefined' ? null : Origins.find(o => o.id == origin).module;
    if (module == null || typeof module !== 'string' || module == '') {
      this.messageService.success('El proceso de pago finalizó exitosamente', null, () => {
        this.go2Home();
      });
      return;
    }
    const toGo = typeof postRoutes.find(r => r.id == module) === 'undefined' ? null : postRoutes.find(r => r.id == module).route;
    if (toGo == null) {
      this.messageService.success('El proceso de pago finalizó exitosamente', null, () => {
        this.go2Home();
      });
      return;
    }
    if (!!params)
      this.router.navigate([toGo, params], { queryParams: queyParams });
    else
      this.router.navigate([toGo], { queryParams: queyParams, state: state });
  }

  isCanceled() {
    this.messageService.success('La solicitud de pago no puede ser procesada debido a que fué cancelada previamente.', null,
      () => { this.go2Home() });
  }

  // Utilities
  go2Home() {
    this.router.navigate(['/']);
  }

  estimatedTime() {
    this.changeWaitingText();
  }

  exitEstimatedTime() {
    if (this.estimatedTimeTimer) {
      clearTimeout(this.estimatedTimeTimer);
    }
    this.waitText = 'Cargando información del pago, por favor espera.';
    this.conuterTexts = 0;
  }

  changeWaitingText() {
    this.estimatedTimeTimer = setTimeout(() => {
      if (this.conuterTexts >= WaitMessagePayment.length) {
        this.conuterTexts = WaitMessagePayment.length - 1;
      }
      this.waitText = WaitMessagePayment[this.conuterTexts];
      this.conuterTexts = this.conuterTexts + 1;
      this.changeWaitingText();
    }, 28000);
  }

  onComplete(event: IEventPyment) {
    if (this.isPaying) {
      this.isPaying = false;
    }
    if (event.success) {
      document.getElementsByTagName('body')[0].removeAttribute('style');
      this.requestPaymentForm.get('positiveBalancePay').disable({ onlySelf: true });
      this.bbSelector(this.profile.id, this.paymentRequestId, this.profile.id, event)
        .pipe(untilDestroyed(this)).subscribe({
          next: (response: IMinResponse<any, any>) => {
            if (!!response) {
              if (!!response.additionalData) {
                if (!!response.additionalData['originService']) {
                  console.log(this.rulesToDisplay.customEnding);
                  if (!!this.rulesToDisplay.customEnding) {
                    console.log(response.additionalData['originService']);
                    this.goTo(response.additionalData['originService'], null, {}, { requestId: response.data, paymentRequestId: this.paymentRequestId });
                    return;
                  }
                }
              }
              if (response.data != null && typeof response.data !== 'undefined') {
                this.paymentProcessedId = response.data;
                this.isPaymentDone(this.paymentProcessedId);
                this.paymentApplied = true;
                this.requestPaymentForm.get('paymentChannel').disable({ onlySelf: true, emitEvent: false });
                return;
              }
            }
            this.messageService.info(`Estimado alumno:<br>Te informamos  que tu pago ha sido procesado. Sin embargo se presentó un problema al generar tu documento.
            <br>Favor de acudir a Servicios Escolares de tu campus.`,
              null, () => this.router.navigate(['/user/profile']));
          }, error: error => {
            this.messageService.info(`Estimado alumno:<br>Te informamos  que tu pago ha sido procesado. Sin embargo se presentó un problema al generar tu documento.
            <br>Favor de acudir a Servicios Escolares de tu campus.`,
              null, () => this.router.navigate(['/user/profile']));
          }
        });
    } else {
      this.aceptConditions = false;
      this.clicked = false;
      if (this.requestPaymentForm.get('paymentChannel').value === PAYMENT_CHANNELS_CAT.codi) {
        this.cancelAndReturn()
      } else {
        if (event.data && event.data['additionalData'] && `${event.data['additionalData']}` === 'NOMESSAGE') {
          return;
        }
        this.messageService.info('El pago no se pudo completar');
      }
    }
  }

  getPaymentSummary() {
    if (!this.alreadyPaymentApplied) {
      if (this.checkPaymentSuscriber) {
        this.checkPaymentSuscriber.unsubscribe();
      }
      this.alreadyPaymentApplied = true;
      this.proccesPayHadFinished = true;

      const navigationExtras: NavigationExtras = { state: { module: this.paymentOriginModule } };
      this.router.navigate(['payment/summary', this.paymentRequestId], navigationExtras);
    }
  }

  bbSelector(userId: string, paymentId: string, profileUserId: string, event: IEventPyment): Observable<IMinResponse<any, any>> {
    if (event.paymentMethode == PAYMENT_CHANNELS_CAT.online) {
      if (this.__bankbridge_version_ == 2) {
        return this.paymentService.requestPaymentStatusV2(userId, paymentId, profileUserId);
      }
      return this.paymentService.requestPaymentStatus(userId, paymentId, profileUserId);
    }
    else if (event.paymentMethode == PAYMENT_CHANNELS_CAT.codi) {
      if (event.data == null || (event.data['data'] == null && event.data['additionalData'] == null)) {
        event.paymentMethode = this.paymentChannels.find(c => c.canOnlyPB).key;
        return this.bbSelector(userId, paymentId, profileUserId, event);
      } else {
        return of(event.data as IMinResponse<any, any>);
      }
    }
    else if (event.paymentMethode == PAYMENT_CHANNELS_CAT.flywire) {
      if (event.data == null || (event.data['data'] == null && event.data['additionalData'] == null)) {
        event.paymentMethode = this.paymentChannels.find(c => c.canOnlyPB).key;
        return this.bbSelector(userId, paymentId, profileUserId, event);
      } else {
        return of(event.data as IMinResponse<any, any>);
      }
    }
    else if (event.paymentMethode == PAYMENT_CHANNELS_CAT.mercadopago) {
      if (event.data == null || (event.data['data'] == null && event.data['additionalData'] == null)) {
        event.paymentMethode = this.paymentChannels.find(c => c.canOnlyPB).key;
        return this.bbSelector(userId, paymentId, profileUserId, event);
      } else {
        return of(event.data as IMinResponse<any, any>);
      }
    }

  }

  diplayInfoToPay() {
    return this.isLoadedPayment && !this.isPaying;
  }

  diplayInfoToPayLoading() {
    return !this.isLoadedPayment && !this.isPaying;
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

}
