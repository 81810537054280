import { Component, OnInit } from '@angular/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WelcomeDialogComponent } from '../../components/welcome-dialog/welcome-dialog.component';
import { ViewEncapsulation } from '@angular/core';
import { MessageTypes } from '../../../../core/interfaces/imessage-dialog-data';
import { ConfigService } from '../../../../core/config/services/config.service';
import { MfaDialogComponent } from '../../components/mfa-dialog/mfa-dialog.component';
import { AssetsService } from 'src/app/core/api/assets/assets.service';
import { AuthService } from 'src/app/core/session/services/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { default_responsiveOptions } from '../../components/slider/slider.component';

library.add(
  faGraduationCap,
  faCreditCard,
  faQuestionCircle
);

@UntilDestroy()
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginPageComponent extends BaseComponent implements OnInit {

  slideInfoConfig: {
    slidesToShow: 1,
    slideToScroll: 1,
    autoPlay: true,
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
  };
  responsiveOptions = default_responsiveOptions;
  slideInfo = [
    { text: 'Tira de materias' },
    { text: 'Historial Académico' },
    { text: 'Boleta de Preparatoria' },
    { text: 'Detalle de Asistencias' },
    { text: 'Empresas de Servicio Social' },
    { text: 'Calendario Escolar' },
    { text: 'Reglamentos de Alumnos' },
    { text: 'Horarios del Próximo Ciclo' },
    { text: 'Consulta de Saldos' },
    { text: 'Colegiaturas' },
    { text: 'Reinscripción en línea' },
  ];

  mfaDialogRef: MatDialogRef<MfaDialogComponent>;

  sselUrl: string = '';
  constructor(
    private dialog: MatDialog,
    private config: ConfigService,
    private assets: AssetsService,
    private authService: AuthService
  ) {
    super();
    //this.sselUrl = `${this.config.get('ssel.url')}${this.config.get('ssel.legacyLogin')}`;
  }

  ngOnInit() {
    setTimeout(this.openMfaDialog.bind(this), 500);
  }

  openWelcomeDialog() {
    return;
    if (!this.dialog.getDialogById(`message-${MessageTypes.error}`)) {
      this.dialog.open(WelcomeDialogComponent, {
        maxWidth: '400px',
        autoFocus: false,
        panelClass: 'welcome-dialog-pane',
        hasBackdrop: true,
        disableClose: true
      });
    }
  }

  openMfaDialog() {
    this.assets.getMfaMessage().pipe(untilDestroyed(this)).subscribe({
      next: (data) => {
        if (data) {
          this.mfaDialog(data);
        } else {
          this.openWelcomeDialog();
        }
      }, error: () => {
        this.openWelcomeDialog();
      }
    });
  }

  mfaDialog(msg: any) {
    console.log(!this.authService.isAuthenticated() || !this.authService.canAccess());
    if (!this.authService.isAuthenticated() || !this.authService.canAccess()) {
      if (!this.dialog.getDialogById(`message-${MessageTypes.error}`)) {
        this.mfaDialogRef = this.dialog.open(MfaDialogComponent, {
          maxWidth: '400px',
          autoFocus: false,
          panelClass: 'welcome-dialog-pane',
          hasBackdrop: true,
          disableClose: true,
          data: { msg: msg }
        });

        this.mfaDialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(() => {
          setTimeout(this.openWelcomeDialog.bind(this), 100);
        });
      }
    }
  }
}
